import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import GlobalTop from "../components/globalTop"
import ContactBanner from "../components/contactBanner"
import { Link } from "gatsby"

const Medika1 = () => (
  <Layout>
    <SEO title="Medika 1" />
    <GlobalTop title="Medika 1" />
    <section className="medika1-content">
      <div className="container">
        <h2 className="section--header text-center">
          Nasza <strong>misja</strong>
        </h2>
        <div className="row align-items-center">
          <div className="col-md-6">
            <h2 className="section--header">Poradnia naczyniowa Medika 1</h2>
            <p>
              <strong>
                Witaj w naszej firmie medycznej Medika 1 poradni naczyniowej!
                Nasza placówka to miejsce, gdzie dbamy o Twoje zdrowie i
                dobrostan.
              </strong>{" "}
              Specjalizujemy się w diagnostyce i leczeniu chorób naczyniowych, a
              naszym priorytetem jest zapewnienie kompleksowej opieki pacjentom.
              <br />
              <br />
              Nasz zespół składa się z doświadczonych specjalistów, którzy mają
              na uwadze Twoje potrzeby i dobro. W naszych szeregach pracuje
              lekarz, który specjalizuje się w angiologii - Ania Płotek, jest
              ekspertem w dziedzinie chorób naczyniowych. Jej wiedza i
              doświadczenie pozwalają na precyzyjne diagnozy i skuteczne
              leczenie.
            </p>
            <Link to="/cennik/" className="medika1-content__price-link">
              <img
                className="img-fluid"
                src={require("../assets/images/medika1-ico-1.png")}
                alt=""
              />
              Cennik usług
            </Link>
          </div>
          <div className="col-md-6 text-center">
            <img
              className="img-fluid"
              src={require("../assets/images/medika1-image-1.png")}
              alt=""
            />
          </div>
        </div>
        <div className="row align-items-center">
          <div className="col-md-6 text-center">
            <img
              className="img-fluid"
              src={require("../assets/images/medika1-image-2.png")}
              alt=""
            />
          </div>
          <div className="col-md-6">
            <h2 className="section--header">
              Usługi pielęgniarskie na miejscu lub w domu pacjenta
            </h2>
            <p>
              To jednak nie wszystko! Dla zapewnienia pełnej opieki, do naszej
              poradni dołączyła również pielęgniarka Justyna Janisz. Jej
              profesjonalizm i empatia sprawiają, że jesteśmy w stanie zapewnić
              Ci wsparcie na każdym etapie leczenia.
              <br />
              <br />
              <strong>
                W naszej firmie medycznej łączymy wiedzę, innowacyjne
                technologie i troskę o pacjenta. Stawiamy na najwyższą jakość
                świadczonych usług, dbając o Twój komfort i bezpieczeństwo.
              </strong>
              <br />
              <br />
              Cieszymy się na możliwość opieki nad Tobą i zapewnienia Ci
              kompleksowej diagnostyki oraz skutecznego leczenia. Zaufaj naszemu
              doświadczeniu i oddaniu dla pacjentów, by razem podążać ku zdrowiu
              i lepszemu jutru.
              <br />
              <strong>
                Zapraszamy do naszej poradni naczyniowej, gdzie tworzymy zespół
                profesjonalistów gotowych zadbać o Twoje zdrowie!
              </strong>
            </p>
          </div>
        </div>
        <div className="medika1-content__offer-grid col">
          <div className="row justify-content-center">
            {/* {wpMedika.nodes.map((node, index) => (
              <div className="col-xl-4 col-lg-5 col-sm-6" key={index}>
                <Link
                  to={`/oferta/medika1${node.uri}`}
                  className="offer-single-services__tile"
                  style={{
                    backgroundImage: `url(${
                      node.acfPodologia.podologiaGridIcon.sourceUrl
                        ? node.acfPodologia.podologiaGridIcon.sourceUrl
                        : ""
                    })`,
                  }}
                >
                  <h4>{node.title}</h4>
                </Link>
              </div>
            ))} */}
            <div className="col-sm-12 col-md-6 col-lg-4">
              <div className="global-offer__tile global-offer__tile-1">
                <h4>
                  Poradnia
                  <br />
                  chorób
                  <br />
                  naczyń
                </h4>
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-4">
              <Link
                to="/oferta/medika1/badanie-usg-doppler"
                className="global-offer__tile global-offer__tile-2"
              >
                <h4>
                  Badania USG
                  <br />
                  kończyn
                  <br />
                  naczyń
                </h4>
              </Link>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-4">
              <div className="global-offer__tile global-offer__tile-3">
                <h4>
                  Leczenie
                  <br />
                  ran
                </h4>
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-4">
              <Link
                to="/oferta/medika1/obrzeki"
                className="global-offer__tile global-offer__tile-4"
              >
                <h4>
                  Leczenie
                  <br />
                  obrzęków
                  <br />
                  kończyn
                </h4>
              </Link>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-4">
              <div className="global-offer__tile global-offer__tile-5">
                <h4>Medika 1</h4>
              </div>
            </div>
          </div>
        </div>
        <ContactBanner />
      </div>
    </section>
  </Layout>
)

export default Medika1

// export default () => (
//   <StaticQuery
//     query={graphql`
//       {
//         wpMedika: allWpMedika {
//           nodes {
//             uri
//             id
//             slug
//             title
//             acfPodologia {
//               podologiaGridIcon {
//                 sourceUrl
//               }
//             }
//           }
//         }
//       }
//     `}
//     render={data => <Medika1 {...data} />}
//   />
// )
